@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');



.active {
  color: #97BB45 !important;
}

.logo-slider-part .rfm-child {
  margin-right: 72px;
}

.logo-slider-block .rfm-child {
  margin-right: 78px;
}

.react-tel-input .form-control {
  font-size: 14px !important;
  width: 100% !important;
  height: auto !important;
  border: 1px solid #E5E5E5 !important;
  line-height: 157% !important;
  padding-left: 55px !important;
}

.react-tel-input .form-control:focus{
  border: 1px solid #8CBC3F !important;
}

.react-tel-input .selected-flag {
  background: transparent !important;
  padding: 0 0 0 12px !important;
  border-radius: 7px 0 0 7px !important;
  -webkit-border-radius: 7px 0 0 7px !important;
  -moz-border-radius: 7px 0 0 7px !important;
  -ms-border-radius: 7px 0 0 7px !important;
  -o-border-radius: 7px 0 0 7px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag{
  background: transparent !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.react-tel-input .selected-flag::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: #E5E5E5;
  top: 0;
  bottom: 0;
  right: -10px;
}

.react-tel-input .selected-flag .arrow{
  left: 22px !important;
  border-top: 4px solid #6A8395 !important;
}

.react-tel-input .selected-flag .arrow.up{
  border-top: none !important;
  border-bottom: 4px solid #6A8395 !important;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
  background-color: transparent !important;
}

textarea{
  resize: none;
}

html {
  scroll-behavior: smooth;
}

.message-success {
  color: #155724; /* Green text */
  background-color: #d4edda; /* Light green background */
  border: 1px solid #c3e6cb;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  border-radius: 5px;
  animation: fadeIn 1s ease-out; /* Optional fade-in animation */
}

.message-error {
  color: #721c24; /* Red text */
  background-color: #f8d7da; /* Light red background */
  border: 1px solid #f5c6cb;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  border-radius: 5px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
